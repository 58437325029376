<template>
  <div>
    <v-card-title class="display-1 mb-2">{{t('$vuetify.serviceCenter.requestCharger')}}</v-card-title>
    <v-card-subtitle>{{t('$vuetify.serviceCenter.contactDetails')}}</v-card-subtitle>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-card-text>
          <v-row>
            <v-flex xs12>
              <v-text-field
                class="mx-2"
                v-model="payload.email"
                :rules="[rules.required, rules.email]"
                :validate-on-blur="false"
                :error="error"
                :label="t('$vuetify.serviceCenter.email')"
                name="email"
                @keyup.enter="submit"
                @change="resetErrors"
              ></v-text-field>
            </v-flex>
          </v-row>
          <v-row>
            <v-flex xs12>
              <v-text-field
                class="mx-2"
                v-model="payload.phone"
                :rules="[rules.required, rules.phone]"
                :validate-on-blur="false"
                :error="error"
                :label="t('$vuetify.serviceCenter.phone')"
                name="phone"
                @keyup.enter="submit"
                @change="resetErrors"
              ></v-text-field>
            </v-flex>
          </v-row>
          <v-row>
            <v-flex xs12 class="mx-2">
              {{t('$vuetify.serviceCenter.gdprTitle')}} <br>
              {{t('$vuetify.serviceCenter.gdprExplain')}}
              <v-checkbox
                v-model="isDiffAdrs"
                :rules="[rules.required]"
                :label="t('$vuetify.serviceCenter.gdprAccept')"
              ></v-checkbox>
            </v-flex>
          </v-row>
        </v-card-text>
      </v-form>
    <v-divider></v-divider>
    <v-card-actions class="justify-end">
      <!-- {{t('$vuetify.serviceCenter.totPrice')}} : {{totPrice}} € -->
      <v-spacer />
      <v-btn
          color="primary"
          text
          @click="previousStep()"
      >
          <v-icon>mdi-chevron-left</v-icon>
          {{t('$vuetify.generic.back')}}
      </v-btn>
      <v-btn
          color="success"
          text
          @click="nextStep()"
      >
        {{t('$vuetify.generic.next')}}
      </v-btn>
      <v-btn
        color="red"
        text
        @click="cancel"
      >
        {{t('$vuetify.generic.cancel')}}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserApi from '@/services/UserApi'

export default {
  data () {
    return {
      payload: {
        email: '',
        phone: ''
      },
      isDiffAdrs: false,
      langs: ['en', 'de'],

      // sign in buttons
      isLoading: false,
      isSignInDisabled: false,

      // form
      isFormValid: true,
      email: '',
      password: '',

      // form error
      error: false,
      errorMessages: '',

      errorProvider: false,
      errorProviderMessages: '',

      // show password field
      showPassword: false,

      isVerified: false,
      // input rules
      rules: {
        required: (value) => (value && Boolean(value)) || 'Required',
        vatCheck: v => {
          if (/^(BE)?0[0-9]{9}$/.test(v)) return true
          return 'invalide vat should be lik BEXXXXXXXXXX'
        },
        zipCode: v => {
          if (!v.trim()) return true
          if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999) return true
          return 'Number has to be between 0 and 9999'
        },
        email: v => {
          if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v)) return true
          return 'Invalid email'
        },
        phone: v => {
          if (/^(?:(?:\+|00)32|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(v)) return true
          return 'Invalid phone should be like 04XXXXXXXX'
        }
      }
    }
  },
  mounted () {
    console.log('MOUNTED')
    this.isVerified = this.$route.query.Verified
    this.loadConfigPage()
    this.init()
  },
  computed: {
    ...mapGetters({ price: 'requestsState/price', totPrice: 'requestsState/totalPrice' })
  },
  methods: {
    async init () {
      const dt = await new UserApi().userInfo()
      this.payload.email = dt.data.data.RelationContact.Email // Street Address (delivery) - string
      this.payload.phone = dt.data.data.RelationContact.Telephone // Postal Code (delivery) - string
    },
    nextStep () {
      this.submit()
    },
    previousStep () {
      this.$store.commit('requestsState/setPreviousStepName')
    },
    forgetPswdLink () {
      // this.$router.push({ name: 'auth-forgot', query: { returnUrl: this.$route.query.ReturnUrl } })
    },
    signupLink () {
      // this.$router.push({ name: 'auth-signup', query: { returnUrl: this.$route.query.ReturnUrl } })
    },
    async loadConfigPage () {
      /* const params = this.$route.query
      try {
        const rslt = await new AuthService.getLoginPage(params.ReturnUrl)
        // TODO
        console.log('[DEBUG CONFIG]')
        console.log(rslt)
      } catch (error) {
        console.log(error)
      } */
    },
    submit () {
      if (this.$refs.form.validate()) {
        this.$store.commit('requestsState/setEmail', this.payload)
        this.$store.commit('requestsState/setPhone', this.payload)
        this.$store.commit('requestsState/setStep', 'ReviewOrder')
      }
    },
    async signIn () {
      /* const params = this.$route.query
      // TODO CHECK if return url exist
      try {
        const payload = {
          username: this.email,
          password: this.password,
          rememberLogin: true,
          returnUrl: this.$route.query.ReturnUrl,
          altUsername: ''
        }
        const rslt = await new AuthService.login(payload)
        if (rslt.data.type === 'Verification') {
          console.log('DEBUG : VERIFICATION')
          // this.$router.push(`/auth/verify-email?email=${rslt.data.email}`)
          console.log(rslt.data.email)
          this.$router.push({ name: 'auth-verify-email', query: { email: rslt.data.email } })
          // this.$router.push('/auth/verify-email')
        } else if (rslt.data.isOk) {
          window.location = rslt.data.redirectUrl
        } else {
          this.resetErrors()
          this.isLoading = false
          this.isSignInDisabled = false
        }
        console.log(rslt)
      } catch (error) {
        console.log(error)
      } */
    },
    resetErrors () {
      this.error = false
      this.errorMessages = ''

      this.errorProvider = false
      this.errorProviderMessages = ''
    },
    cancel () {
      this.init()
      this.$emit('cancel')
    }
  }
}
</script>
